import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const { DATETIME_FORMATS: { MONTH_AND_YEAR_TEXT_EXT } } = Constants;

const Tab = ({ data: { label, fromDate, toDate, count } = {}, showBadge = true, active, onClick }) => {
  const handleClick = useCallback(() => {
    onClick({ fromDate, toDate });
  }, [fromDate, toDate, onClick]);

  return (
    <div
      className={classNames(Css.tab, active && Css.active, !count && Css.completed)}
      onClick={handleClick}>
      <span className={Css.label}>{label}</span>
      {!!count && showBadge && <Badge counter className={Css.badge}>{count}</Badge>}
    </div>
  );
};

const MonthsTabsVertical = ({ data, disabled, className }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [{ fromDate, toDate }, setEnvVars] = useEnvVars();

  const tabs = useMemo(() => {
    const result = data
      .filter((item) => item.startDate)
      .reduce((accumulator, currentItem) => {
        const { startDate } = currentItem;

        const momentDate = moment.utc(startDate);

        const key = momentDate.format("YYYY-MM");

        const monthData = accumulator.find((item) => item.key === key);

        const uncompleted = currentItem.status !== DataConstants.TASK_STATUSES.COMPLETED;

        if (monthData) {
          if (uncompleted) monthData.count++;

          return accumulator;
        }

        return [...accumulator, {
          key,
          count: uncompleted ? 1 : 0,
          fromDate: momentDate.startOf("month").format(Constants.DATETIME_FORMATS.API_DATE),
          toDate: momentDate.endOf("month").format(Constants.DATETIME_FORMATS.API_DATE),
          label: moment(momentDate).format(MONTH_AND_YEAR_TEXT_EXT)
        }];
      }, []);

    return Utils.arraySort(result, "key");
  }, [data]);

  const handleTabClick = useCallback((params) => {
    setEnvVars(params);
  }, [setEnvVars]);

  if (!tabs.length) return null;

  return (
    <div className={classNames(Css.monthsTabs, className)}>
      <div className={Css.title}>{uiTexts.periods}</div>
      <div disabled={disabled} className={Css.content}>
        <Tab
          showBadge={false}
          active={!fromDate && !toDate}
          data={{ label: uiTexts.all, count: 1 }}
          onClick={handleTabClick} />
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            data={tab}
            active={fromDate === tab.fromDate && toDate === tab.toDate}
            onClick={handleTabClick} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(MonthsTabsVertical);
