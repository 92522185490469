import Css from "./style.module.scss";

import { Pagination, SelectPageSize } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import NoDataContent from "nlib/common/NoDataContent";
import React, { useCallback } from "react";
import TaskListItem from "nlib/common/TaskListItem";
import useAvailableWidth from "hooks/useAvailableWidth";
import useEnvVars from "hooks/useEnvVars";

const SIMPLE_MODE_MAX_WIDTH = 800;

const COMPACT_MODE_MAX_WIDTH = 550;

const ListView = ({ verticalMonthTabsShowed, tasksData }) => {
  const [{ editTask, page = 1, pageSize = Constants.TABLE_PAGE_SIZE }, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const [editTaskId] = (editTask || "").split(".");

  const { containerRef, availableWidth } = useAvailableWidth([verticalMonthTabsShowed, editTaskId]);

  const simpleMode = availableWidth <= SIMPLE_MODE_MAX_WIDTH;

  const compactMode = availableWidth <= COMPACT_MODE_MAX_WIDTH;

  const reducedTasksData = tasksData.slice((page - 1) * pageSize, page * pageSize);

  const handleSelectTask = useCallback((taskId) => {
    setEnvVars({ editTask: taskId, editItem: null });
  }, [setEnvVars]);

  const handlePageChange = useCallback((value) => {
    setEnvVars({ page: value });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((value) => {
    setEnvVars({ pageSize: value, page: 1 });
  }, [setEnvVars]);

  if (!tasksData.length) {
    return (
      <div className={Css.listView}>
        <NoDataContent className={Css.noData} title={uiTexts.nothingFound} />
      </div>
    );
  }

  return (
    <div className={Css.listView} ref={containerRef}>
      <div className={Css.header}>
        <div className={Css.checkBoxWrapper} />
        <div className={Css.content} title={uiTexts.description}>{uiTexts.description}</div>
        {!businessUser && <div className={Css.status} title={uiTexts.status}>{uiTexts.status}</div>}
        {!simpleMode && <div className={Css.startDate} title={uiTexts.startDate}>{uiTexts.startDate}</div>}
        {!compactMode && <div className={Css.dueDate} title={uiTexts.dueDate}>{uiTexts.dueDate}</div>}
        {!simpleMode && <div className={Css.user} title={uiTexts.taskFor}>{uiTexts.taskFor}</div>}
        {!simpleMode && <div className={Css.createdBy} title={uiTexts.created}>{uiTexts.created}</div>}
        {!compactMode && <div className={Css.info} />}
      </div>
      {reducedTasksData.map((task) => (
        <TaskListItem
          key={task.id}
          task={task}
          selected={editTaskId === task.id}
          simpleMode={simpleMode}
          compactMode={compactMode}
          onSelect={handleSelectTask} />
      ))}
      <div className={Css.footer}>
        {(tasksData.length > Number(pageSize)) && (
          <Pagination
            count={tasksData.length}
            page={Number(page)}
            pageSize={Number(pageSize)}
            onChange={handlePageChange} />
        )}
        {(tasksData.length > Constants.TABLE_PAGE_SIZE) && (
          <SelectPageSize
            pageSize={Number(pageSize)}
            onChange={handlePageSizeChange} />
        )}
      </div>
    </div>
  );
};

export default React.memo(ListView);
