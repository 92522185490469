import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import { checkIsBusinessUser, getUserData } from "selectors/user";
import { checkTasksFetching } from "selectors/tasks";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Checkbox from "nlib/ui/Checkbox";
import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo } from "react";
import TasksActions from "actions/TasksActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import useShowCommonModal from "hooks/useShowCommonModal";
import useTaskContent from "hooks/useTaskContent";

const {
  TASK_STATUSES: { IN_PROGRESS, ON_HOLD, COMPLETED }
} = DataConstants;

const MAX_TASK_TITLE_LENGTH = 128;

const STATUSES_CLASS_NAMES = {
  [IN_PROGRESS]: Css.inProgress,
  [ON_HOLD]: Css.onHold,
  [COMPLETED]: Css.completed
};

const TaskListItem = ({ disabled, selected, simpleMode, compactMode, task, onSelect, fetchData }) => {
  const {
    id: taskId,
    createdBy,
    comments,
    status,
    attachmentsCount = 0,
    autoTask = false
  } = task;

  const {
    completed,
    startDateContent,
    dueDateContent,
    dueDateStatus,
    createdByContent,
    createdAtContent,
    taskForContent
  } = useTaskContent(task);

  const title = task.title || task.preview;

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { id: userId, role: userRole } = useSelector(getUserData);

  const { uiTexts, messages } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const fetchTasks = useSelector(checkTasksFetching);

  const canBeManaged = useMemo(() => {
    return !autoTask && (userRole === UserRoles.ACCOUNTANT_ADMIN.roleId || (createdBy && createdBy.id === userId));
  }, [autoTask, userRole, createdBy, userId]);

  const createdByAtContent = useMemo(() => {
    return [createdBy && createdByContent, createdAtContent].filter(Boolean).join(", ");
  }, [createdBy, createdByContent, createdAtContent]);

  const handleItemClick = useCallback(() => {
    onSelect(taskId);
  }, [taskId, onSelect]);

  const handleCompleteClick = useCallback(async(event) => {
    event.stopPropagation();

    if (autoTask) return;

    const newStatus = completed ? IN_PROGRESS : COMPLETED;

    const result = await dispatch(TasksActions.editTask(taskId, { status: newStatus }, true));

    if (result) {
      const { COMMENT_TARGET_TYPES: { TASKS }, COMMENT_TYPES: { COMMENT } } = DataConstants;

      await dispatch(
        CommentsActions.addNewComment(
          COMMENT,
          Utils.replaceTextVars(messages.taskStatusChangedTo, {
            status: uiTexts[newStatus]
          }),
          [],
          false,
          taskId,
          TASKS
        )
      );
      if (fetchData && !completed) fetchData();
    }
  }, [autoTask, completed, dispatch, taskId, messages, uiTexts, fetchData]);

  const handleRemoveClick = useCallback(async(event) => {
    event.stopPropagation();

    const result = await showCommonModal({ confirm: true, text: messages.taskDeleteConfirm });

    if (result) await dispatch(TasksActions.deleteTask(taskId));
  }, [taskId, messages, dispatch, showCommonModal]);

  return (
    <div
      disabled={disabled}
      className={classNames(
        Css.taskListItem,
        selected && Css.selected,
        businessUser
          ? (status === COMPLETED ? Css.completed : null)
          : STATUSES_CLASS_NAMES[status],
        autoTask && Css.autoTask
      )}
      data-status={businessUser ? (completed ? status : null) : status}
      onClick={handleItemClick}>
      <div
        disabled={disabled || fetchTasks}
        className={Css.checkBoxWrapper}
        title={completed ? uiTexts.unmarkAsComplete : uiTexts.markAsComplete}
        onClick={handleCompleteClick}>
        <Checkbox className={Css.checkBox} checked={completed} />
      </div>
      <div className={Css.content}>
        <div>{title + (title.length >= MAX_TASK_TITLE_LENGTH ? "..." : "")}</div>
      </div>
      {!businessUser && (
        <div className={Css.status}>
          <div data-status={status} title={uiTexts[status]}>
            {uiTexts[status]}
          </div>
        </div>
      )}
      {!simpleMode && (
        <div className={Css.startDate}>
          {!!startDateContent && <Badge>{startDateContent}</Badge>}
        </div>
      )}
      {!compactMode && (
        <div className={Css.dueDate}>
          {!!dueDateContent && <Badge theme={!completed && dueDateStatus}>{dueDateContent}</Badge>}
        </div>
      )}
      {!simpleMode && (
        <div className={Css.user}>
          {taskForContent && (
            <div>
              <Icons.User weight="bold" />
              <span title={taskForContent}>{taskForContent}</span>
            </div>
          )}
        </div>
      )}
      {!simpleMode && (
        <div className={Css.createdBy}>
          <div title={createdByAtContent}>{createdByAtContent}</div>
        </div>
      )}
      {!compactMode && (
        <div className={Css.info}>
          <div className={classNames(Css.item, Css.attachments)}>
            <Icons.FileText />
            <Badge counter>{attachmentsCount}</Badge>
          </div>
          <div className={classNames(Css.item, Css.comments)}>
            <Icons.ChatText />
            <Badge counter theme={comments.unread && "attention"}>{comments.all}</Badge>
          </div>
          <div className={Css.item}>
            {canBeManaged && (
              <Button
                light
                className={Css.remove}
                onClick={handleRemoveClick}>
                <Icons.Trash />
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(TaskListItem);
