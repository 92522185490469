import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useDispatch, useSelector } from "react-redux";
import Column from "./lib/Column";
import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TasksActions from "actions/TasksActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";
import useWindowResize from "hooks/useWindowResize";

const {
  TASK_STATUSES: { TO_DO, IN_PROGRESS, ON_HOLD, COMPLETED },
  COMMENT_TARGET_TYPES: { TASKS },
  COMMENT_TYPES: { COMMENT }
} = DataConstants;

const TASK_STATUSES_LIST = [TO_DO, IN_PROGRESS, ON_HOLD, COMPLETED];

const BoardView = ({ tasksData }) => {
  const [{ editTask }] = useEnvVars();

  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const boardViewRef = useRef(null);

  const [dragStart, setDragStart] = useState(null);

  const [leftArrowShown, setLeftArrowShown] = useState(false);

  const [rightArrowShown, setRightArrowShown] = useState(false);

  const [draggedCartData, setDraggedCartData] = useState(null);

  const handleMouseMove = useCallback((event) => {
    boardViewRef.current.scrollLeft = dragStart.scrollLeft - (event.clientX - dragStart.x);
  }, [dragStart]);

  const handleMouseDown = useCallback((event) => {
    setDragStart({ x: event.clientX, scrollLeft: boardViewRef.current.scrollLeft });
  }, []);

  const handleMouseUp = useCallback(() => {
    setDragStart(null);
  }, []);

  const handleWindowResize = useCallback(() => {
    setRightArrowShown(boardViewRef.current.scrollWidth > boardViewRef.current.clientWidth);
    setLeftArrowShown(boardViewRef.current.scrollLeft > 0);
  }, []);

  const handleArrowLeftClick = useCallback(() => {
    boardViewRef.current.scrollLeft = 0;
  }, []);

  const handleArrowRightClick = useCallback(() => {
    boardViewRef.current.scrollLeft = boardViewRef.current.scrollWidth - boardViewRef.current.clientWidth;
  }, []);

  const handleScroll = useCallback(() => {
    setLeftArrowShown(boardViewRef.current.scrollLeft > 0);
    setRightArrowShown(boardViewRef.current.scrollWidth
        > Math.pow(boardViewRef.current.clientWidth + boardViewRef.current.scrollLeft));
  }, []);

  useWindowResize(handleWindowResize);

  useEffect(() => {
    if (dragStart) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (dragStart) {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [dragStart, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    return monitorForElements({
      onDragStart: (params) => {
        setDraggedCartData(params.source.data);
      },
      onDrop: async(params) => {
        const {
          location: { current: { dropTargets: [dropTarget] } },
          source: { data: { taskId } = {} }
        } = params;

        setDraggedCartData(null);

        if (!dropTarget) return;

        const { data: { status } = {} } = dropTarget;

        if (!status || !taskId) return;

        const { status: prevStatus, autoTask } = Utils.arrayFindById(tasksData, taskId, {});

        if (!prevStatus || status === prevStatus || (autoTask && status === COMPLETED)) return;

        const result = await dispatch(TasksActions.editTask(taskId, { status }, true, true));

        if (result) {
          const message = Utils.replaceTextVars(messages.taskStatusChangedTo, { status: uiTexts[status] });

          dispatch(CommentsActions.addNewComment(COMMENT, message, [], false, taskId, TASKS));
        }
      }
    });
  }, [tasksData, messages, uiTexts, dispatch]);

  useEffect(() => {
    handleWindowResize();
  }, [editTask, handleWindowResize]);

  return (
    <div className={classNames(Css.boardView, (leftArrowShown || rightArrowShown) && Css.scrollable)}>
      <div className={Css.scroll} onMouseDown={handleMouseDown} onScroll={handleScroll} ref={boardViewRef}>
        {TASK_STATUSES_LIST.map((status) => {
          return (
            <Column key={status} status={status} draggedCartData={draggedCartData} tasksData={tasksData} />
          );
        })}
      </div>
      {leftArrowShown && (
        <div className={Css.arrowLeft} onClick={handleArrowLeftClick}>
          <Icons.CaretLeft weight="bold" />
        </div>
      )}
      {rightArrowShown && (
        <div className={Css.arrowRight} onClick={handleArrowRightClick}>
          <Icons.CaretRight weight="bold" />
        </div>
      )}
    </div>
  );
};

export default React.memo(BoardView);
